/* istanbul ignore file */

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDocumentTitle } from 'usehooks-ts'
import { useAuthenticationToken } from '../common/hooks/use-authentication-token'
import type { MountState } from '../common/restAPI'
import { authSSOLogin, getAuthUser, getOrganization, useMounted } from '../common/restAPI'
import type { LegacyLoginCallback } from '../common/types/legacy-types'
import type { OrganizationRawJsProtobuf, UserRawJsProtobuf } from '../common/types/raw-javascript-protobuf-types'
import { FullPageWait } from './full-page-wait'

export function LogIn({
  loginCallback: login,
  ssoResponseCode,
  ssoState,
  hasExternalSSOError: hasExternalSSOError,
}: {
  readonly loginCallback: LegacyLoginCallback
  readonly ssoResponseCode?: string
  readonly ssoState?: string
  // The SSO workflow encountered an error and sent the user here to try again
  readonly hasExternalSSOError: boolean
}) {
  const { clearAuthenticationToken, setAuthenticationToken } = useAuthenticationToken()

  const navigate = useNavigate()

  const loggingIn = ssoResponseCode && ssoState

  const [errors, setErrors] = useState(hasExternalSSOError)

  const loginFailed = useCallback(() => {
    console.log('Failed to sign in')
    clearAuthenticationToken()
    setErrors(true)
  }, [clearAuthenticationToken])

  const onUserTokenReceived = useCallback(
    (key: string | undefined, next: string | undefined, mountState: Readonly<MountState>) => {
      if (!key) {
        loginFailed()
        return
      }

      setAuthenticationToken(key)

      // Get the full user info
      getAuthUser(
        mountState,
        (info: UserRawJsProtobuf) => {
          getOrganization(
            info.getOrgId(),
            mountState,
            (orgInfo: OrganizationRawJsProtobuf) => {
              login(info, orgInfo, key)
              setErrors(false)
              navigate(next ?? '/', { replace: true })
            },
            loginFailed,
          )
        },
        loginFailed,
      )
    },
    [login, loginFailed, navigate, setAuthenticationToken],
  )

  useDocumentTitle('Sign In | AI Hub')

  // attempt sso login with code returned from openID callback redirect
  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mountState, tearDownMounted] = useMounted()

    if (ssoResponseCode && ssoState) {
      authSSOLogin(
        ssoResponseCode,
        ssoState,
        mountState,
        ({ key, next }: { readonly key: string; readonly next: string | undefined }) => {
          onUserTokenReceived(key, next, mountState)
        },
        () => {
          setErrors(true)
        },
      )
    }

    return tearDownMounted
  }, [ssoResponseCode, ssoState, onUserTokenReceived])

  if (loggingIn && !errors) {
    return <FullPageWait />
  }

  return (
    <div className="main">
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form action="/accounts/sso_login/" method="POST">
              <input type="hidden" name="provider" value="qc-sso" readOnly={true} />
              <input type="hidden" name="process" value="login" readOnly={true} />
              <input type="hidden" name="callback_url" value="/" readOnly={true} />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 5, mb: 5 }}>
                Sign in with Qualcomm MyAccount
              </Button>
            </form>

            {errors && <Alert severity="error">Unable to sign in. New User? Please try again in a few minutes.</Alert>}
          </Box>
        </Box>
      </Container>
    </div>
  )
}
